import '../scss/styles.scss'

import * as bootstrap from 'bootstrap'
import GLightbox from 'glightbox'
import Lenis from 'lenis'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules';

// Register GSAP plugins //
gsap.registerPlugin(ScrollTrigger)

// Tooltips //
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

//Actual Year
const d = new Date();
let year = d.getFullYear();
document.getElementById("year").innerHTML = year;


//Glightbox//
const lightbox = GLightbox({
    zoomable: false
  });
 


//LENIS//
const lenis = new Lenis({
  smooth: true,
  prevent: (node) => 
    node.classList.contains('overlay') || 
    node.classList.contains('cky-modal') || 
    node.closest('.overlay') || 
    node.closest('.cky-modal')
});

lenis.on('scroll', ScrollTrigger.update);

gsap.ticker.add((time) => {
  lenis.raf(time * 1000); // Convert time from seconds to milliseconds
});

gsap.ticker.lagSmoothing(0);



// GSAP JS //

// MENU//
var menu = document.querySelector('.btn-menu');
let menuOpen = false;

const showAnim = gsap.from('.sticky-menu', { 
  yPercent: -100,
  paused: true,
  duration: 0.2
}).progress(1);

ScrollTrigger.create({
  start: "top top",
  end: "max",
  toggleClass: {className: 'sticky-menu--scrolled', targets: '.sticky-menu'},
  onUpdate: (self) => {
    self.direction === -1 ? showAnim.play() : showAnim.reverse()
  }
});

var tl = gsap.timeline({
  paused: true,
  onComplete: () => menuOpen = true,
  onReverseComplete: () => menuOpen = false
})

tl.to(".overlay", {
  right: 0,
  duration: 0.3,
  ease: "power2.inOut"
})

tl.from(".wrap", {
  x: 100,
  duration: 0.5,
  opacity: 0,
  ease: "back.out(1.7)"
})

tl.from(".close", {
  x: 50,
  duration: 0.3,
  opacity: 0,
  ease: "power2.out"
})

menu.addEventListener("click", function(e) {
  e.preventDefault();
  
  if (menuOpen) {
    tl.reverse();
  } else {
    tl.play();
  }
});


document.addEventListener('click', function(e) {
  if (menuOpen && (!e.target.closest('.overlay') || e.target.closest('.close'))) {
    tl.reverse();
  }
});

// MENU END //


// SWIPER //

//SWIPER reference//

var swiper = new Swiper(".refSwiper", {
  modules: [Navigation],
  grabCursor: true,
  autoHeight: true,

  navigation: {
    nextEl: ".ref-next",
    prevEl: ".ref-prev",
  },

  keyboard: {
    enabled: true,
  },
});

//SWIPER Aktuality//

var swiper = new Swiper(".newsSwiper", {
  modules: [Navigation, Pagination],
  grabCursor: true,
  autoHeight: true,
  slidesPerView: 1,
  spaceBetween: 16,

   // Responsive breakpoints
   breakpoints: {
    
    768: {
      slidesPerView: 2,
      spaceBetween: 24
    },
  
    992: {
      slidesPerView: 3,
      spaceBetween: 32
    }
  },


  pagination: {
    el: '.swiper-pagination',
  },

  navigation: {
    nextEl: ".news-next",
    prevEl: ".news-prev",
  },

  keyboard: {
    enabled: true,
  },
});

//SWIPER galerie//

var swiper = new Swiper(".galerieSwiper", {
  modules: [Navigation ],
  grabCursor: true,
  
  navigation: {
    nextEl: ".gal-next",
    prevEl: ".gal-prev",
  },

  keyboard: {
    enabled: true,
  },
});

//SWIPER Tipy na výlet//

var swiper = new Swiper(".placeSwiper", {
  modules: [Navigation, Pagination],
  grabCursor: true,
  autoHeight: true,
  slidesPerView: 1,
  spaceBetween: 16,

   // Responsive breakpoints
   breakpoints: {
    
    992: {
      slidesPerView: 2,
      spaceBetween: 24
    }
  },

  pagination: {
    el: '.swiper-pagination',
  },

  navigation: {
    nextEl: ".place-next",
    prevEl: ".place-prev",
  },

  keyboard: {
    enabled: true,
  },
});
